import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/cha/assinar-premium": [~3],
		"/cha/assinar-premium/dados-do-cartao": [~4],
		"/cha/assinar-premium/dados-do-pix": [~5],
		"/cha/assinar-premium/modo-de-pagamento": [~6],
		"/cha/assinar-premium/pagamento": [7],
		"/cha/assinar-premium/pix": [8],
		"/cha/bem-vinda": [~9],
		"/cha/compartilhar-cha": [~10],
		"/cha/historico-da-votacao": [~11],
		"/cha/meus-presentes": [~12],
		"/cha/meus-presentes/gift-extract": [~13],
		"/cha/meus-presentes/register-bank-account": [~14],
		"/cha/meus-presentes/register-bank-account/register": [15],
		"/cha/meus-presentes/register-pix-key": [~16],
		"/cha/meus-presentes/register-pix-key/register": [17],
		"/cha/meus-presentes/request-gifts": [~18],
		"/cha/meus-presentes/request-gifts/confirm-request/success-screen": [20],
		"/cha/meus-presentes/request-gifts/confirm-request/[id]": [~19],
		"/cha/meus-presentes/request-gifts/edit-account/[id]": [~21],
		"/cha/meus-presentes/request-gifts/edit-pix-key/[id]": [~22],
		"/cha/para-comecar": [~23],
		"/cha/personalizar-revelacao": [~24],
		"/cha/profile/[uuid]": [~25],
		"/cha/profile/[uuid]/result": [~26],
		"/cha/profile/[uuid]/result/share-link-gender": [~27],
		"/cha/selecao-de-emoji": [~28],
		"/cha/selecao-de-nomes": [~29],
		"/cha/tema": [~30],
		"/checking-reveals": [~31],
		"/congratulations": [32],
		"/donations/[revealId]": [~33],
		"/donations/[revealId]/dados-do-cartao": [~34],
		"/donations/[revealId]/modo-de-pagamento": [~35],
		"/donations/[revealId]/pagamento": [~36],
		"/donations/[revealId]/pix": [~37],
		"/error/front": [38],
		"/login": [~39],
		"/meu-cha/[reveal_id]": [~40],
		"/notice-screen": [~41],
		"/notice-screen/select-gender": [~42],
		"/sentry-example": [43],
		"/suporte": [44],
		"/transicao": [45]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';