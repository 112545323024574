import {handleErrorWithSentry, replayIntegration} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

const vercelEnv = import.meta.env.VITE_VERCEL_ENV ?? 'local';

if (vercelEnv !== 'local') {
	Sentry.init({
		dsn: 'https://4fd7e1e42af65306c36c6a2453799e2e@o4507645147283456.ingest.us.sentry.io/4507645148725248',
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		integrations: [replayIntegration()],
	});
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
